import React, { useState, useRef } from "react";
import * as Yup from "yup";
import axios from "axios";
import swal from "sweetalert";
import ReCAPTCHA from "react-google-recaptcha";
import "./Modal.scss";
import { Formik, Form, Field } from "formik";

const Modal = (props) => {
  const captchaRef = useRef();

  // Esquema de validación con Yup
  const SignupSchema = Yup.object().shape({
    name: Yup.string().min(2, "Mínimo 2 caracteres").max(100, "Máximo 100 caracteres").required("Campo requerido"),
    phone: Yup.string().matches(/^[0-9]{10}$/, "Debe tener 10 dígitos").required("Campo requerido"),
    email: Yup.string().email().required("Campo requerido"),
    contactTime: Yup.string().min(2, "Mínimo 2 caracteres").max(50, "Máximo 50 caracteres").required("Campo requerido"),
    recaptcha: Yup.string().required("Llenar captcha"),
  });

  // Obtener el siguiente ID desde el servidor
  const obtenerSiguienteID = async () => {
    try {
      const response = await fetch("https://vistavelasunset.grupovelas.com/get_next_id.php");
      if (!response.ok) throw new Error(`Error HTTP: ${response.status}`);
      const data = await response.json();
      return data.next_id || null;
    } catch (error) {
      console.error("Error al obtener el siguiente ID:", error);
      return null;
    }
  };

  // Función para descargar el brochure
  const descargarBrochure = () => {
    const link = document.createElement("a");
    link.href = "/assets/Home/Brochure_PuntaVela_opt.pdf";
    link.target = "_blank";
    link.download = "Brochure.pdf";
    link.click();
  };

  return (
    <div className="contenedor_modal">
      <p onClick={() => props.setModal(false)} className="closed">X</p>
      <div className="contenedor_modal_form">
        <img src="/assets/Home/iconos-logos/img_logo_puntavela.svg" alt="" />
        <h2>¿DESEAS MÁS INFORMACIÓN?</h2>
        <p>Completa tus datos y, en breve, uno de nuestros asesores te contactará para brindarte más detalles sobre nuestras residencias.</p>

        <Formik
          initialValues={{
            name: "",
            phone: "",
            email: "",
            contactTime: "",
            recaptcha: "",
          }}
          validationSchema={SignupSchema}
          onSubmit={async (values, { resetForm }) => {
            const siguienteID = await obtenerSiguienteID();
            if (!siguienteID) {
              swal("Error", "No se pudo obtener el siguiente ID.", "error");
              return;
            }

            const urlActual = window.location.href;

            // Datos para el primer endpoint (vistavelasunset)
            const data1 = {
              id: siguienteID,
              Nombre: values.name,
              Apellidos: "Sin Apellido",
              Telefono: values.phone,
              Movil: values.phone,
              Correo: values.email,
              Descripcion: values.contactTime,
              ClaveDesarrollo: "jrz-004",
              Campana: "CP-0160",
              Medio: "MPUB-0022",
              CorreoAgente: "erios@velasresorts.com",
              CorreoAgenteTitular: "erios@velasresorts.com",
              TipoPersona: "F",
              referer: urlActual,
            };

            // Datos para el segundo endpoint (puntavela-juarez)
            const data2 = {
              name: values.name,
              phone: values.phone,
              email: values.email,
              contactTime: values.contactTime,
              recaptcha: values.recaptcha,
            };

            console.log("Enviando datos a vistavelasunset:", data1);
            console.log("Enviando datos a puntavela-juarez:", data2);

            try {
              // Enviar datos a vistavelasunset
              const response1 = await axios.post("https://vistavelasunset.grupovelas.com/send_lead.php", data1);

              // Enviar datos a puntavela-juarez
              const response2 = await axios.post("https://puntavela-juarez.com/php/sendEmailPuntaVela.php", data2);

              console.log("Respuesta de vistavelasunset:", response1.data);
              console.log("Respuesta de puntavela-juarez:", response2.data);

              if (response1.data.success && response2.data.success) {
                swal("Enviado", "Los datos han sido enviados correctamente", "success");

                // Resetear formulario y captcha
                resetForm();
                captchaRef.current?.reset();

                // Descargar brochure
                descargarBrochure();
              } else {
                throw new Error("Error en la respuesta del servidor");
              }
            } catch (error) {
              console.error("Error en la solicitud:", error);
              swal("Error", "Hubo un problema al enviar el formulario.", "error");
            }
          }}
        >
          {({ errors, touched, handleSubmit, setFieldValue }) => (
            <Form onSubmit={handleSubmit}>
              <Field name="name" placeholder="Nombre" />
              {errors.name && touched.name ? <div className="error">{errors.name}</div> : null}

              <Field name="phone" type="text" placeholder="Teléfono" />
              {errors.phone && touched.phone ? <div className="error">{errors.phone}</div> : null}

              <Field name="email" type="email" placeholder="Correo electrónico" />
              {errors.email && touched.email ? <div className="error">{errors.email}</div> : null}

              <Field name="contactTime" placeholder="Horario de contacto preferido" />
              {errors.contactTime && touched.contactTime ? <div className="error">{errors.contactTime}</div> : null}

              <Field name="recaptcha">
                {({ meta }) => {
                  const handleChange = (value) => setFieldValue("recaptcha", value);
                  return (
                    <>
                      <ReCAPTCHA
                        ref={captchaRef}
                        sitekey="6Ldod54pAAAAANWSl_Uk3F9bYRp656TRSO2lgqun"
                        onChange={handleChange}
                      />
                      {meta.error && meta.touched ? <div className="error">{meta.error}</div> : null}
                    </>
                  );
                }}
              </Field>

              <p>Al enviar acepta nuestra <a onClick={() => props.setModalAviso(true)}>Política de privacidad</a></p>

              <button className="button_send" type="submit">Enviar</button>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default Modal;
